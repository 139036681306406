import React from 'react'
import Sidebar from '../layouts/Sidebar'
import { ChildrenType } from '../types'
import Header from '../layouts/Header'

const Template = ({title,children}: ChildrenType) => {
  return (
    <div>
      <Header />
      <Sidebar />
      <main id="content" role="main" className="main">
          <div className="content container-fluid">
            <div className="pb-3 mb-3 page-header">
              <div className="row align-items-center">
                <div className="col">
                  <h1 className="page-header-title">{title}</h1>
                </div>
               
              </div>
             
            </div>
            {
              children
            }

          </div>
         

      </main>
    </div>
  )
}

export default Template
