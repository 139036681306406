import { createSlice } from "@reduxjs/toolkit";

const uniteSlice = createSlice({
    name: 'unitControl',
    initialState: {
        value: true,
    },
    reducers: {
        controlHandler: (state,action) => {
            state.value = action.payload
        }
    }
});

export const {controlHandler} = uniteSlice.actions;
export default uniteSlice.reducer;