
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";

export const DateRangePickerComponent = (props: any) => {
  const { onChange, startDate , endDate  } = props;
  return (
    <DateRangePicker onCallback={onChange}>
      <button className='btn btn-outline-primary float-end' >{startDate && endDate ? moment(startDate).format('YYYY-MM-DD') + ' - '+ moment(endDate).format('YYYY-MM-DD') : 'Pick Up Date'}</button>
    </DateRangePicker>
  );
};
