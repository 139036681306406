import React, { useEffect, useState } from 'react'
import Template from '../utils/Template'
import { ToastContainer } from 'react-toastify'
import { getMethodService, postMethodService } from '../services';
import { CHANGE_PASSWORD_API, LOGIN_API, SAVE_SENIOR_REPORT_API, SENIORS_LIST_API, SENIOR_ALL_REPORT_API, SENIOR_REPORT_API, WINNER_API } from '../services/constants';
import { useNavigate } from 'react-router-dom';
import { HandleLogout } from '../utils/Helper';
import TableScroll from '../utils/TableScroll';
import TableLoading from '../utils/TableLoading';
import { useQuery } from '@tanstack/react-query';
import { stateObjType } from '../types';
import Swal from 'sweetalert2';
import { momentDateFormat } from '../services/helper';

const ProfitLoss = () => {
  const [isLoading,setIsLoading] = useState(false);
  const [seniors,setSeniors] = useState<any>([]);
  const [report,setReport] = useState<any>({});
  const [reports,setReports] = useState([]);
  const [refreshKey, setRefreshKey] = useState(0);
  const navigate = useNavigate();
  const [senior,setSenior] = useState<any>("")

  const fetchSeniors = () => getMethodService(`${SENIORS_LIST_API}?sortDirection=asc`);
  const seniorsQuery = useQuery({ queryKey: [refreshKey], queryFn: fetchSeniors, refetchOnWindowFocus: false});

  const fetchSeniorReport = () => getMethodService(`${SENIOR_REPORT_API}?senior_id=${JSON.parse(senior)?.id}`);
  const seniorReportQuery = useQuery({ queryKey: [senior], queryFn: () => senior && fetchSeniorReport(), refetchOnWindowFocus: false});

  const fetchSeniorReports = () => getMethodService(`${SENIOR_ALL_REPORT_API}?senior_id=${JSON.parse(senior)?.id}`);
  const seniorReportsQuery = useQuery({ queryKey: [senior,refreshKey], queryFn: () => senior && fetchSeniorReports(), refetchOnWindowFocus: false});

  useEffect(() => {
    if(seniorsQuery){
        setIsLoading(seniorsQuery.isLoading);
        if(seniorsQuery?.data === 'Unauthenticated.' || seniorsQuery?.data === 'E_UNAUTHORIZED_ACCESS: Unauthorized access'){
            HandleLogout(navigate);
        };
        if(seniorsQuery?.data?.status === "success"){
            setSeniors(seniorsQuery?.data?.data);
        };
    };
}, [seniorsQuery]);

useEffect(() => {
    if(seniorReportQuery){
        setIsLoading(seniorReportQuery.isLoading);
        if(seniorReportQuery?.data === 'Unauthenticated.' || seniorReportQuery?.data === 'E_UNAUTHORIZED_ACCESS: Unauthorized access'){
            HandleLogout(navigate);
        };
        if(seniorReportQuery?.data?.status === "success"){
            setReport(seniorReportQuery?.data?.data);
        };
    };
}, [seniorReportQuery]);

useEffect(() => {
    if(seniorReportsQuery){
        setIsLoading(seniorReportsQuery.isLoading);
        if(seniorReportsQuery?.data === 'Unauthenticated.' || seniorReportsQuery?.data === 'E_UNAUTHORIZED_ACCESS: Unauthorized access'){
            HandleLogout(navigate);
        };
        if(seniorReportsQuery?.data?.status === "success" || seniorReportsQuery?.data?.success === "success"){
            setReports(seniorReportsQuery?.data?.data);
        };
    };
}, [seniorReportsQuery]);

   
  const saveReportSubmitHandler = () => {
    Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00c9a7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Confirm!",
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
            if(report?.username && senior){
                const data = {
                    senior_id: JSON.parse(senior)?.id,
                    mm_two_profit: report?.mm_two_profit,
                    ml_two_profit: report?.ml_two_profit,
                    db_two_profit: report?.db_two_profit,
                    gl_two_profit: report?.gl_two_profit,
                    mm_three_profit: report?.mm_three_profit,
                    db_three_profit: report?.db_three_profit,
                    mm_two_percent: report?.mm_two_percent,
                    ml_two_percent: report?.ml_two_percent,
                    db_two_percent: report?.db_two_percent,
                    gl_two_percent: report?.gl_two_percent,
                    mm_three_percent: report?.mm_three_percent,
                    db_three_percent: report?.db_three_percent,
                    profit: report?.profit,
                    up_pay: report?.net,
                    down_pay: report?.down_pay,
                    total_down_pay: report?.total_down_pay,
                    pout_thee : report?.pout_thee
                };
    
                postMethodService(SAVE_SENIOR_REPORT_API, data, true)
                .then(res => {
                    setIsLoading(false);
                    if(res === 'Unauthenticated.' || res === "E_UNAUTHORIZED_ACCESS: Unauthorized access"){
                        HandleLogout(navigate);
                    };
                    if(res?.status === "success"){
                        Swal.fire({
                            title: "Success!",
                            text: "Report have been clear",
                            icon: "success"
                        });
                        setRefreshKey(prev => prev + 1);
                    };
                });
            };  
        }
      }); 
  };
  
  return (
    <>
        <Template title="Senior Report">
            <div className="card mb-3">
                <div className="card-header">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-sm-5">
                            <h4 className="card-title">
                                Report
                            </h4>
                        </div>
                        <div className="col-sm-4">
                            <div className="tom-select-custom tom-select-custom-with-tags">
                                <div className="tom-select-custom">
                                    <select className="js-select form-select" value={senior} autoComplete="off"
                                            data-hs-tom-select-options='{
                                                "placeholder": "Select user..."
                                            }'
                                            onChange={e => setSenior(e.target.value)}
                                        >
                                        <option value="">Select senior</option>
                                        {
                                            seniors?.length > 0 &&
                                            seniors.map((senior:stateObjType) => <option key={senior?.id} value={JSON.stringify(senior)}>{senior?.username}</option>)
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    senior &&
                    <div className="card-body">
                        <div className="row">
                            <div className="col-sm-12">
                                    <TableScroll>
                                        <div>
                                            <table className="table text-white w-100 table-bordered table-striped">
                                                <thead>
                                                    <tr style={{backgroundColor: 'rgb(70 70 227)'}}>
                                                        <th className="p-2 text-center text-light">No</th>
                                                        <th className="p-2 text-center text-light">အိုင်ဒီ</th>
                                                        <th className="p-2 text-center text-light">လက်ကျန်မှတ်</th>
                                                        <th className="p-2 text-center text-light">ဝယ်မှတ်</th>
                                                        <th className="p-2 text-center text-light">ပြန်ထုတ်မှတ်</th>
                                                        <th className="p-2 text-center text-light">ပေးမှတ်</th>
                                                        <th className="p-2 text-center text-light">ပြန်ရမှတ်</th>
                                                        <th className="p-2 text-center text-light">2Dအသားတင်</th>
                                                        <th className="p-2 text-center text-light">မလေး2Dအသားတင်</th>
                                                        <th className="p-2 text-center text-light">ဒူဘိုင်း2Dအသားတင်</th>
                                                        <th className="p-2 text-center text-light">Gold2Dအသားတင်</th>
                                                        <th className="p-2 text-center text-light">3Dအသားတင်</th>
                                                        <th className="p-2 text-center text-light">ဒူဘိုင်း 3Dအသားတင်</th>
                                                        <th className="p-2 text-center text-light">2D % ( {JSON.parse(senior)?.two_percentage} )</th>
                                                        <th className="p-2 text-center text-light">မလေး2D % ( {JSON.parse(senior)?.malay_percentage} )</th>
                                                        <th className="p-2 text-center text-light">ဒူဘိုင်း % ( {JSON.parse(senior)?.dubai_percentage} )</th>
                                                        <th className="p-2 text-center text-light">Gold % ( {JSON.parse(senior)?.gold_percentage} )</th>
                                                        <th className="p-2 text-center text-light">3D % ( {JSON.parse(senior)?.three_percentage} )</th>
                                                        <th className="p-2 text-center text-light">ဒူဘိုင်း 3D % ( {JSON.parse(senior)?.dubai_three_percentage} )</th>
                                                        <th className="p-2 text-center text-light">အမြတ်</th>
                                                        <th className="p-2 text-center text-light">အပေါ်ပေး</th>
                                                        <th className="p-2 text-center text-light">ပေါက်သီး</th>
                                                        <th className="p-2 text-center text-light">စုစုပေါင်းအပေါ်ပေး</th>
                                                        <th className="p-2 text-center text-light">စုစုပေါင်းလက်ကျန်မှတ်</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {isLoading && <TableLoading col={24} />}
                                                    {
                                                        senior &&
                                                        <tr style={{verticalAlign: 'middle'}}>
                                                            <td className="p-2 text-center text-dark">1</td>
                                                            <td className="p-2 text-center text-dark">{report?.username}</td>
                                                            <td className="p-2 text-center text-dark">{report?.amount}</td>
                                                            <td className="p-2 text-center text-dark">{report?.buy_amount}</td>
                                                            <td className="p-2 text-center text-dark">{report?.sell_amount}</td>
                                                            <td className="p-2 text-center text-dark">{report?.send_amount}</td>
                                                            <td className="p-2 text-center text-dark">{report?.receive_amount}</td>
                                                            <td className="p-2 text-center text-dark">{report?.mm_two_profit}</td>
                                                            <td className="p-2 text-center text-dark">{report?.ml_two_profit}</td>
                                                            <td className="p-2 text-center text-dark">{report?.db_two_profit}</td>
                                                            <td className="p-2 text-center text-dark">{report?.gl_two_profit}</td>
                                                            <td className="p-2 text-center text-dark">{report?.mm_three_profit}</td>
                                                            <td className="p-2 text-center text-dark">{report?.db_three_profit}</td>
                                                            <td className="p-2 text-center text-dark">{report?.mm_two_percent}</td>
                                                            <td className="p-2 text-center text-dark">{report?.ml_two_percent}</td>
                                                            <td className="p-2 text-center text-dark">{report?.db_two_percent}</td>
                                                            <td className="p-2 text-center text-dark">{report?.gl_two_percent}</td>
                                                            <td className="p-2 text-center text-dark">{report?.mm_three_percent}</td>
                                                            <td className="p-2 text-center text-dark">{report?.db_three_percent}</td>
                                                            <td className="p-2 text-center text-dark">{report?.profit}</td>
                                                            <td className="p-2 text-center text-dark">{report?.net}</td>
                                                            <td className="p-2 text-center text-dark">{report?.pout_thee}</td>
                                                            <td className="p-2 text-center text-dark"> 
                                                            {Math.sign(report?.total_down_pay) === -1 ? <span style={{color : 'red'}}>-</span> : <span style={{color : 'black'}}>+</span>}  {Math.abs(report?.total_down_pay)}
                                                            </td>
                                                            <td className="p-2 text-center text-dark">{report?.user_amount}</td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </TableScroll>
                            </div>
                            <div className="col-sm-12">
                                <div className='mt-4'>
                                    <button className='btn btn-primary float-end' onClick={saveReportSubmitHandler} disabled={isLoading}>
                                        {
                                            isLoading? 'Loading...':'စာရင်ရှင်းမယ်'
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
            {
                senior &&
                <div className="card mb-3">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-sm-12">
                                {
                                    <TableScroll>
                                        <div>
                                            <table className="table text-white w-100 table-bordered table-striped">
                                                <thead>
                                                    <tr style={{backgroundColor: 'rgb(1, 1, 60)'}}>
                                                        <th className="p-2 text-center text-light">No</th>
                                                        <th className="p-2 text-center text-light">နေ့စွဲ</th>
                                                        <th className="p-2 text-center text-light">2Dအသားတင်</th>
                                                        <th className="p-2 text-center text-light">မလေး2Dအသားတင်</th>
                                                        <th className="p-2 text-center text-light">ဒူဘိုင်း2Dအသားတင်</th>
                                                        <th className="p-2 text-center text-light">Gold2Dအသားတင်</th>
                                                        <th className="p-2 text-center text-light">3Dအသားတင်</th>
                                                        <th className="p-2 text-center text-light">ဒူဘိုင်း 3Dအသားတင်</th>
                                                        <th className="p-2 text-center text-light">2D % အကောင့်</th>
                                                        <th className="p-2 text-center text-light">မလေး2D % အကောင့်</th>
                                                        <th className="p-2 text-center text-light">ဒူဘိုင်း % အကောင့်</th>
                                                        <th className="p-2 text-center text-light">Gold % အကောင့်</th>
                                                        <th className="p-2 text-center text-light">3D % အကောင့်</th>
                                                        <th className="p-2 text-center text-light">ဒူဘိုင်း 3D % အကောင့်</th>
                                                        <th className="p-2 text-center text-light">အမြတ်</th>
                                                        <th className="p-2 text-center text-light">အပေါ်ပေး</th>
                                                        <th className="p-2 text-center text-light">အောက်ပေး</th>
                                                        <th className="p-2 text-center text-light">ပေါက်သီး</th>
                                                        <th className="p-2 text-center text-light">စုစုပေါင်းအပေါ်ပေး</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {isLoading && <TableLoading col={24} />}
                                                    {
                                                        reports?.length > 0 ?
                                                        reports?.map((report:stateObjType,i: number) => 
                                                            <tr style={{verticalAlign: 'middle'}} key={report?.id}>
                                                                <td className="p-2 text-center text-dark">{i + 1}</td>
                                                                <td className="p-2 text-center text-dark">{momentDateFormat(report?.created_at)}</td>
                                                                <td className="p-2 text-center text-dark">{report?.mm_two_profit}</td>
                                                                <td className="p-2 text-center text-dark">{report?.ml_two_profit}</td>
                                                                <td className="p-2 text-center text-dark">{report?.db_two_profit}</td>
                                                                <td className="p-2 text-center text-dark">{report?.gl_two_profit}</td>
                                                                <td className="p-2 text-center text-dark">{report?.mm_three_profit}</td>
                                                                <td className="p-2 text-center text-dark">{report?.db_three_profit}</td>
                                                                <td className="p-2 text-center text-dark">{report?.mm_two_percent}</td>
                                                                <td className="p-2 text-center text-dark">{report?.ml_two_percent}</td>
                                                                <td className="p-2 text-center text-dark">{report?.db_two_percent}</td>
                                                                <td className="p-2 text-center text-dark">{report?.gl_two_percent}</td>
                                                                <td className="p-2 text-center text-dark">{report?.mm_three_percent}</td>
                                                                <td className="p-2 text-center text-dark">{report?.db_three_percent}</td>
                                                                <td className="p-2 text-center text-dark">{report?.profit}</td>
                                                                <td className="p-2 text-center text-dark">{report?.up_pay}</td>
                                                                <td className="p-2 text-center text-dark">{report?.down_pay}</td>
                                                                <td className="p-2 text-center text-dark">{report?.pout_thee}</td>
                                                                <td className="p-2 text-center text-dark"> 
                                                                    {Math.sign(report?.total_down_pay) === -1 ? <span style={{color : 'red'}}>-</span> : <span style={{color : 'black'}}>+</span>}  {Math.abs(report?.total_down_pay)}
                                                                </td>
                                                            </tr>
                                                        )
                                                        :
                                                        <tr>
                                                            <td className='text-center' colSpan={24}>No found data</td>
                                                        </tr>   
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </TableScroll>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
        </Template>
        <ToastContainer />
    </>
  )
}

export default ProfitLoss