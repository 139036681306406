import React, { useEffect, useState } from 'react'
import classes from '../assets/styles/Toggle.module.css'
const Toggle = ({id,status,forId,event}) => {
    return (
        <div className={classes['switch-container']}>
            <input type="checkbox" className={classes.checkbox} id={forId} checked={!status} onChange={() => {
                event(status,id);
            }}/>
            <label className={classes.switch} htmlFor={forId}>
                <span className={classes.slider}></span>
            </label>
        </div>
      )
    }

export default Toggle