import moment from "moment"


export const momentTimeFormat = (time) => {
    return moment(time, ["HH:mm:ss"]).format("hh:mm:ss A") ?? 'Invalid Time'
}

export const momentDateFormat = (date) => {
    return moment(date, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD h:mm:ss A') ?? 'Invalid Date';
}

export const betType = (type) => {
    switch (type) {
        case "MM":
            return "မြန်မာ";
        case "ML":
            return "မလေး"
        case "DB":
            return "ဒူဘိုင်း"
        case "GL":
            return "ဂိုးလ်"

        default:
            return "Unknown"
    }
    
}
