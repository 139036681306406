import React from 'react';
import './App.css';
import Routers from './routes';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

document.addEventListener("wheel", function(event){
  if(document.activeElement.type === "number"){
      document.activeElement.blur();
  }
});
const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Routers />
    </QueryClientProvider>
  );
}

export default App;
