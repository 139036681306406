import React, { useEffect, useState } from 'react'
import Template from '../utils/Template'
import { ToastContainer } from 'react-toastify'
import { postMethodService } from '../services';
import { useNavigate } from 'react-router-dom';
import { HandleLogout } from '../utils/Helper';
import CusInput from '../utils/CusInput';
import { CREATE_SENIOR_API } from '../services/constants';

const CreateSenior = () => {
    const [isLoading,setIsLoading] = useState(false);
    const [namely,setNamely] = useState<any>("");
    const [username,setUsername] = useState<any>("")
    const [password,setPassword] = useState<any>("");
    const [conPass,setConPass] = useState<any>("");
    const [twoLimit,setTwoLimit] = useState<any>("");
    const [threeLimit,setThreeLimit] = useState<any>("");
    const [malayLimit,setMalayLimit] = useState<any>("");
    const [dubaiLimit,setDubaiLimit] = useState<any>("");
    const [goldLimit,setGoldLimit] = useState<any>("");
    const [dubaiThreeLimit,setDubaiThreeLimit] = useState<any>("");
    const [twoPercent,setTwoPercent] = useState<any>("");
    const [threePercent,setThreePercent] = useState<any>("");
    const [malayPercent,setMalayPercent] = useState<any>("");
    const [dubaiPercent,setDubaiPercent] = useState<any>("");
    const [goldPercent,setGoldPercent] = useState<any>("");
    const [dubaiThreePercent,setDubaiThreePercent] = useState<any>("");
    const [shanPercent,setShanPercent] = useState<any>("");
    const [amount,setAmount] = useState<any>("");

    const [passwordError,setPasswordError] = useState("");
    const [conPasswordError,setConPasswordError] = useState("");
    const navigate = useNavigate();

    const passwordHandler = (e:React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
        setPasswordError("");
    };

    const conPasswordHandler = (e:React.ChangeEvent<HTMLInputElement>) => {
        setConPass(e.target.value);
        setConPasswordError("");
    };

    const createSubmitHandler = (e:React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const data = {
            name: namely,
            username,
            password,
            password_confirmation: conPass,
            amount,
            allowed_amount: twoLimit,
            allowed_amount_three: threeLimit,
            allowed_amount_malay: malayLimit,
            allowed_amount_dubai: dubaiLimit,
            allowed_amount_gold: goldLimit,
            allowed_amount_dubai_three: dubaiThreeLimit,
            two_percentage: twoPercent,
            three_percentage: threePercent,
            malay_percentage: malayPercent,
            dubai_percentage: dubaiPercent,
            gold_percentage: goldPercent,
            dubai_three_percentage: dubaiThreePercent,
            shan_percentage: shanPercent
        };
        
        postMethodService(CREATE_SENIOR_API, data, true)
        .then(res => {
            setIsLoading(false);
            if(res === 'Unauthenticated.' || res === "E_UNAUTHORIZED_ACCESS: Unauthorized access"){
              HandleLogout(navigate);
            };
            if(res?.status === "success"){
                navigate('/users/senior');
            };
        });
    }
    

   
  return (
    <>
        <Template title='Senior'>
            <div className="card mb-3 mb-lg-5">
                <div className="card-header">
                    <div className="row align-items-center">
                        <div className="col-sm-5">
                            <h4 className="card-title mb-2 mb-sm-0">
                                Create Senior
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-12">
                            <form onSubmit={createSubmitHandler}>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="namely">Name</label>
                                            <input type="text" id="namely" className="form-control" placeholder="Name" value={namely} onChange={e => setNamely(e.target.value) } />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="username">Username</label>
                                            <input type="text" id="username" className="form-control" placeholder="Username" value={username} onChange={e => setUsername(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <CusInput title="Password" type="password" error={passwordError} tabIndex={1} placeholder="********" name="newPassowrd" value={password} event={passwordHandler}/>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <CusInput title="Confirm password" type="password" error={conPasswordError} tabIndex={2} placeholder="********" name="conPassowrd" value={conPass} event={conPasswordHandler}/>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="amount">Amount</label>
                                            <input type="number" id="amount" className="form-control" placeholder="Amount" value={amount} onChange={e => setAmount(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="twodlimit">MM 2D Limit</label>
                                            <input type="number" id="twodlimit" className="form-control" placeholder="2D Limit" value={twoLimit} onChange={e => setTwoLimit(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="goldlimit">Gold 2D Limit</label>
                                            <input type="number" id="goldlimit" className="form-control" placeholder="Gold Limit" value={goldLimit} onChange={e => setGoldLimit(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="dubailimit">Dubai 2D Limit</label>
                                            <input type="number" id="dubailimit" className="form-control" placeholder="Dubai Limit" value={dubaiLimit} onChange={e => setDubaiLimit(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="malaylimit">Malay 2D Limit</label>
                                            <input type="number" id="malaylimit" className="form-control" placeholder="Malay Limit" value={malayLimit} onChange={e => setMalayLimit(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="threedlimit">MM 3D Limit</label>
                                            <input type="number" id="threedlimit" className="form-control" placeholder="3D Limit" value={threeLimit} onChange={e => setThreeLimit(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="dubaithreelimit">Dubai 3D Limit</label>
                                            <input type="number" id="dubaithreelimit" className="form-control" placeholder="Dubai 3D Limit" value={dubaiThreeLimit} onChange={e => setDubaiThreeLimit(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="twodpercent">MM 2D %</label>
                                            <input type="number" id="twodpercent" className="form-control" placeholder="2D Percentage" value={twoPercent} onChange={e => setTwoPercent(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="goldpercent">Gold 2D %</label>
                                            <input type="number" id="goldpercent" className="form-control" placeholder="Gold Percentage" value={goldPercent} onChange={e => setGoldPercent(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="dubaipercent">Dubai 2D %</label>
                                            <input type="number" id="dubaipercent" className="form-control" placeholder="Dubai Percentage" value={dubaiPercent} onChange={e => setDubaiPercent(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="malaypercent">Malay 2D %</label>
                                            <input type="number" id="malaypercent" className="form-control" placeholder="Malay Percentage" value={malayPercent} onChange={e => setMalayPercent(e.target.value)}/>
                                        </div>
                                    </div>
                                    
                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="threedpercent">MM 3D %</label>
                                            <input type="number" id="threedpercent" className="form-control" placeholder="3D Percentage" value={threePercent} onChange={e => setThreePercent(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="dubaithreepercent">Dubai 3D %</label>
                                            <input type="number" id="dubaithreepercent" className="form-control" placeholder="Dubai 3D Percentage" value={dubaiThreePercent} onChange={e => setDubaiThreePercent(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="shanpercent">Shan %</label>
                                            <input type="number" id="shanpercent" className="form-control" placeholder="Shan Percentage" value={shanPercent} onChange={e => setShanPercent(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                         <button type='submit' className='btn btn-primary float-end'>Submit</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Template>
        <ToastContainer />
    </>
  )
}

export default CreateSenior
