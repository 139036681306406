import React, { useEffect, useState, useCallback } from 'react'
import { UsersTypes, stateObjType } from '../types'
import Template from '../utils/Template'
import TableScroll from '../utils/TableScroll'
import TableLoading from '../utils/TableLoading'
import { getMethodService, postMethodService } from '../services'
import { useQuery } from '@tanstack/react-query'
import { HandleLogout, amountCommasSparated } from '../utils/Helper'
import { useNavigate } from 'react-router-dom'
import { SENIORS_EDIT_API, USERS_API } from '../services/constants'
import { ToastContainer } from 'react-toastify'
import ModalBox from '../utils/ModalBox';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import Pagi from '../utils/Pagi'
import { DateRangePickerComponent } from '../utils/DateRange'
import { momentDateFormat } from '../services/helper'

const WalletTrans = ({title,endPoint}:any) => {
  const [isLoading,setIsLoading] = useState(false);
  const [transaction,setTransaction] = useState([]);
  const [showSeniorEditModal,setShowSeniorEditModal] = useState(false);
  const [editData,setEditData] = useState<any>(null);
  const [totalPage, setTotalPage] = useState(1);
  const [page, setPage] = useState(1);
  const [from, setFrom] = useState(1);
  const [refreshKey, setRefreshKey] = useState(0);
  const navigate = useNavigate();
  const [startDate , setStartDate] = useState(null);
  const [endDate , setEndDate] = useState(null);

  const fetchTransaction = () => getMethodService(`${USERS_API}${endPoint}?page=${page}&start_date=${startDate}&end_date=${endDate}`);
  const transactionQuery = useQuery({ queryKey: [`${endPoint}`,page,refreshKey, startDate, endDate], queryFn: fetchTransaction, refetchOnWindowFocus: false});

    useEffect(() => {
        if(transactionQuery){
            setIsLoading(transactionQuery.isLoading);
            if(transactionQuery?.data === 'Unauthenticated.' || transactionQuery?.data === 'E_UNAUTHORIZED_ACCESS: Unauthorized access'){
                HandleLogout(navigate);
            };
            if(transactionQuery?.data?.success === "success"){
                setTransaction(transactionQuery?.data?.data);

                if(transactionQuery?.data?.meta){
                    if(transactionQuery?.data?.meta?.current_page > 0){
                        if(transactionQuery?.data?.meta?.per_page && transactionQuery?.data?.meta?.current_page){
                            setFrom((transactionQuery?.data?.meta.per_page * (transactionQuery?.data?.meta?.current_page - 1)) + 1 );
                        };
                    };
                    setTotalPage(Math.ceil(transactionQuery?.data?.meta?.total / transactionQuery?.data?.meta?.per_page));
                };
            };
        };
    }, [transactionQuery]);
    useEffect(()=>{
        ;
    },[editData]);

    const seniorEditSubmitHandler = (e:React.FormEvent<HTMLFormElement>) => {
        const data = {
            
        };

        if(editData){
            setIsLoading(true);
            postMethodService(
                SENIORS_EDIT_API(editData?.id),
                data,true
            )
            .then(res => {
                setIsLoading(false);
                if(res === 'Unauthenticated.' || res === 'E_UNAUTHORIZED_ACCESS: Unauthorized access'){
                    HandleLogout(navigate);
                };
                if(res?.status === "success"){
                    setShowSeniorEditModal(false);
                    setRefreshKey(prevKey => prevKey + 1);
                };
            });
        };   
    };
    const handleChangeValueDateRangePicker = useCallback(
        (start : any, end : any, label : any) => {
          console.log(label);
          setStartDate(start.format('YYYY-MM-DD'))
          setEndDate(end.format('YYYY-MM-DD'))
    
        },
        []
      );
  return (
    <>
      <Template title={title}>
            <div className="card mb-3">
                <div className="card-header">
                    
                    <div className="row align-items-center">
                        <div className="col-sm-5">
                        <h4 className="card-title">
                                {title} lists
                            </h4>
                        </div>
                        <div className="col-sm-7 d-flex justify-content-end">
                            <DateRangePickerComponent startDate={startDate} endDate={endDate} onChange={handleChangeValueDateRangePicker}/>
                        
                        </div>
            </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-12">
                            <TableScroll>
                                <div>
                                    <table className="table text-white w-100 table-bordered table-striped">
                                        <thead>
                                            <tr style={{backgroundColor: 'rgb(70 70 227)'}}>
                                                <th className="p-2 text-center text-light">No</th>
                                                {
                                                    title !== "Seniors Transaction" &&
                                                    <th className="p-2 text-center text-light">From</th>
                                                }
                                                <th className="p-2 text-center text-light">To</th>
                                                <th className="p-2 text-center text-light">Status</th>
                                                <th className="p-2 text-center text-light">Before</th>
                                                <th className="p-2 text-center text-light">Amount</th>
                                                <th className="p-2 text-center text-light">After</th>
                                                <th className="p-2 text-center text-light">Time</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {isLoading && <TableLoading col={8} />}
                                            {
                                                transaction.length > 0 ?
                                                transaction.map((user:stateObjType,i: number) =>
                                                    <tr style={{verticalAlign: 'middle'}} key={i}>
                                                        <td className="p-2 text-center text-dark">{i + from}</td>
                                                        {
                                                            title === "Seniors Transaction" &&
                                                            <>
                                                                <td className="p-2 text-center text-dark">{user?.name}</td>
                                                            </>
                                                        }
                                                        {
                                                            title === "Masters Transaction" &&
                                                            <>
                                                                <td className="p-2 text-center text-dark">{user?.senior_name}</td>
                                                                <td className="p-2 text-center text-dark">{user?.master_name}</td>
                                                            </>
                                                        }
                                                        {
                                                            title === "Agents Transaction" &&
                                                            <>
                                                                <td className="p-2 text-center text-dark">{user?.master_name}</td>
                                                                <td className="p-2 text-center text-dark">{user?.agent_name}</td>
                                                            </>
                                                        }
                                                        {
                                                            title === "Users Transaction" &&
                                                            <>
                                                                <td className="p-2 text-center text-dark">{user?.agent_name}</td>
                                                                <td className="p-2 text-center text-dark">{user?.user_name}</td>
                                                            </>
                                                        }
                                                         <td className="p-2 text-center text-dark">
                                                            <span className={`badge bg-${user?.status === 'deposit'?'success': 'danger'}`}>{user?.status}</span>
                                                         </td>
                                                        {
                                                           title === "Seniors Transaction" &&
                                                            <>
                                                                <td className="p-2 text-center text-dark">{amountCommasSparated(user?.before_amount)}</td>
                                                                <td className="p-2 text-center text-dark">{amountCommasSparated(user?.amount)}</td>
                                                                <td className="p-2 text-center text-dark">{amountCommasSparated(user?.after_amount)}</td>
                                                            </>
                                                        }
                                                        {
                                                           title === "Masters Transaction" &&
                                                            <>
                                                                <td className="p-2 text-center text-dark">{amountCommasSparated(user?.master_before_amount)}</td>
                                                                <td className="p-2 text-center text-dark">{amountCommasSparated(user?.amount)}</td>
                                                                <td className="p-2 text-center text-dark">{amountCommasSparated(user?.master_after_amount)}</td>
                                                            </>
                                                        }
                                                        {
                                                           title === "Agents Transaction" &&
                                                            <>
                                                                <td className="p-2 text-center text-dark">{amountCommasSparated(user?.agent_before_amount)}</td>
                                                                <td className="p-2 text-center text-dark">{amountCommasSparated(user?.amount)}</td>
                                                                <td className="p-2 text-center text-dark">{amountCommasSparated(user?.agent_after_amount)}</td>
                                                            </>
                                                        }
                                                        {
                                                           title === "Users Transaction" &&
                                                            <>
                                                                <td className="p-2 text-center text-dark">{amountCommasSparated(user?.user_before_amount)}</td>
                                                                <td className="p-2 text-center text-dark">{amountCommasSparated(user?.amount)}</td>
                                                                <td className="p-2 text-center text-dark">{amountCommasSparated(user?.user_after_amount)}</td>
                                                            </>
                                                        }
                                                        <td className="p-2 text-center text-dark">{momentDateFormat(user?.updated_at)}</td>
                                                    </tr>
                                                )
                                                :
                                                <tr>
                                                    <td className='text-center' colSpan={8}>No found data</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </TableScroll>
                        </div>
                    </div>
                </div>
                {totalPage > 1 && (
                    <div className="card-footer">
                        <div className="row justify-content-end">
                            <div>
                                <Pagi
                                    setIsLoading={setIsLoading}
                                    setPage={setPage}
                                    totalPage={totalPage}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
      </Template>
      <ToastContainer />
      {/* {showSeniorEditModal && (
            <ModalBox
                title={`Update - ${editData?.name}`}
                onClose={() => setShowSeniorEditModal(false)}
                onSubmit={seniorEditSubmitHandler}
                isLoading={isLoading}
            >
                <Form>
                    <FormGroup>
                        <Label for="amount">2D Limit</Label>
                        <Input type="number" value={twoLimit} onChange={(e:React.ChangeEvent<HTMLInputElement>)=>setTwoLimit(e.target.value)}/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="amount">3D Limit</Label>
                        <Input type="number" value={threeLimit} onChange={(e:React.ChangeEvent<HTMLInputElement>)=>setThreeLimit(e.target.value)}/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="amount">Dubai Limit</Label>
                        <Input type="number" value={dubaiLimit} onChange={(e:React.ChangeEvent<HTMLInputElement>)=>setDubaiLimit(e.target.value)}/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="amount">Malay Limit</Label>
                        <Input type="number" value={malayLimit} onChange={(e:React.ChangeEvent<HTMLInputElement>)=>setMalayLimit(e.target.value)}/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="amount">Shan Limit</Label>
                        <Input type="number" value={shanLimit} onChange={(e:React.ChangeEvent<HTMLInputElement>)=>setShanLimit(e.target.value)}/>
                    </FormGroup>
                    
                    <FormGroup>
                        <Label for="amount">2D %</Label>
                        <Input type="number" value={twoPercent} onChange={(e:React.ChangeEvent<HTMLInputElement>)=>setTwoPercent(e.target.value)}/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="amount">3D %</Label>
                        <Input type="number" value={threePercent} onChange={(e:React.ChangeEvent<HTMLInputElement>)=>setThreePercent(e.target.value)}/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="amount">Dubai %</Label>
                        <Input type="number" value={dubaiPercent} onChange={(e:React.ChangeEvent<HTMLInputElement>)=>setDubaiPercent(e.target.value)}/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="amount">Malay %</Label>
                        <Input type="number" value={malayPercent} onChange={(e:React.ChangeEvent<HTMLInputElement>)=>setMalayPercent(e.target.value)}/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="amount">Shan %</Label>
                        <Input type="number" value={shanPercent} onChange={(e:React.ChangeEvent<HTMLInputElement>)=>setShanPercent(e.target.value)}/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="amount">Banned</Label>
                        <select className="form-control" onChange={e => setBan(e.target.value)} value={ban}>
                            <option value="">Choose ban type</option>
                            <option value={1}>Ban next 1 day</option>
                            <option value={3}>Ban next 3 day</option>
                            <option value={7}>Ban next 7 day</option>
                            <option value={14}>Ban next 14 day</option>
                            <option value={100}>Ban permanently</option>
                            <option value={0}>Unban</option>
                        </select>
                    </FormGroup>
                </Form>
            </ModalBox>
        )} */}
    </>
  )
}

export default WalletTrans
