import { createSlice } from "@reduxjs/toolkit";

const userIdSlice = createSlice({
    name: 'temp_user_id',
    initialState: {
        temp_user_id: [],
    },
    reducers: {
        userIdHandler: (state,action) => {
            state.temp_user_id = action.payload
        }
    }
});

export const {userIdHandler} = userIdSlice.actions;
export default userIdSlice.reducer;