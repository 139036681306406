import { Navigate } from 'react-router-dom';
import { Slide, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import XLSX from 'sheetjs-style';
import * as FileSaver from 'file-saver';
const SweetAlert = require('sweetalert2');
const CryptoJS = require("crypto-js");

export async function copyToClipboard(copyMe) {
    await navigator.clipboard.writeText(copyMe);
    SweetAlert.fire({
        icon: 'success',
        width: 300,
        title: 'Success',
        text: "Copy to Clipboard",
    })
};

export const errorToaster = (message) => {
    toast.error(message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
        hideProgressBar: true,
        theme: 'colored'
    });
};

export const successToaster = (message) => {
    toast.success(message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
        hideProgressBar: true,
        theme: 'colored'
    });
};

export const HandleLogout = (navigate) => {
    const getToken = localStorage.getItem("one_star_a");
    if(getToken){
      localStorage.removeItem("one_star_a");
      window.location.reload();
      return navigate('/login')
    };
};

export const amountCommasSparated = (num) => {
    if(num || num == 0){
        let str = num.toString().split('.');
        if (str[0].length >= 4) {
            str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
        }
        if (str[1] && str[1].length >= 5) {
            str[1] = str[1].replace(/(\d{3})/g, '$1 ');
        }
        return str.join('.');
    }
};

export const popupCenter = ({url, title, w, h}) => {
    const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;

    const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
    const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height;

    const systemZoom = width / window.screen.availWidth;
    const left = (width - w) / 2 / systemZoom + dualScreenLeft
    const top = (height - h) / 2 / systemZoom + dualScreenTop
    const newWindow = window.open(url, title,
      `
      scrollbars=yes,
      width=${w / systemZoom},
      height=${h / systemZoom},
      top=${top},
      left=${left}
      `
    )

    if (window.focus) newWindow?.focus();
}

export const timeFormatMM = (utcDateString) => {
    // UTC date string

    // create new Date object with UTC date string
    const utcDate = new Date(utcDateString);

    // add 6 hours and 30 minutes to UTC date
    const mmtDate = new Date(utcDate.getTime() + 6.5 * 60 * 60 * 1000);

    // format MMT date and time string
    const mmtDateTimeString = mmtDate.toLocaleString("en-US", {
        timeZone: "Asia/Yangon",
    });

    return mmtDateTimeString;
};

export const parlayDetailTime = utcDateString => {
    const utcDate = new Date(utcDateString);
    const mmtDate = new Date(utcDate.getTime());
    const mmtDateTimeString = mmtDate.toLocaleString("en-US", {
        timeZone: "Asia/Yangon",
    });

    return mmtDateTimeString;
}

export const utcToMMT = (dateTime) => {
    const date = new Date(dateTime);
    const formatHour = date.getUTCHours() < 10 ? `0${date.getUTCHours()}`: date.getUTCHours();
    const formatMinute = date.getUTCMinutes() < 10 ? `0${date.getUTCMinutes()}`: date.getUTCMinutes();
    const formatSecond = date.getUTCSeconds() < 10 ? `0${date.getUTCSeconds()}`: date.getUTCSeconds();
    
    const formatYear = date.getUTCFullYear();
    const formatMonth = date.getUTCMonth() + 1 < 10 ? `0${date.getUTCMonth() + 1}`: date.getUTCMonth() + 1;
    const formatDay = date.getUTCDate() < 10 ? `0${date.getUTCDate()}`: date.getUTCDate();

    const timeFormat = `${formatHour}:${formatMinute}:${formatSecond}`;
    const formatDate = `${formatYear}-${formatMonth}-${formatDay}`;
    return `${formatDate} ${timeFormat}`;
}



export const encodeData = data => {
    const deText = CryptoJS.AES.encrypt(JSON.stringify(data), process.env.REACT_APP_SECRET_KEY).toString();
    return deText;
};

export const decodeData = data => {
    try{
        const bytes  = CryptoJS.AES.decrypt(data, process.env.REACT_APP_SECRET_KEY);
        const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return decryptedData;
    }
    catch(err){
        const getFromStore = localStorage.getItem("one_star_a");
        if(getFromStore){
            localStorage.removeItem("one_star_a")
            return  <Navigate to="/login" />
        }else{
            return  <Navigate to="/login" />
        };
    }
};

export const timeFormat = date => {
    let getHours = new Date(date).getHours().toString().padStart(2, '0');
    let getMinutes = new Date(date).getMinutes().toString().padStart(2, '0');
    let getSeconds = new Date(date).getSeconds().toString().padStart(2, '0');
    return getHours + ':' + getMinutes + ':' + getSeconds;
}

export const replaceZeotoQqual = (str) => {
    if(str.includes('+')){
        let splitVal = str.split("+");
        if(splitVal[0] === "0"){
            splitVal[0] = "="
        }else{
            splitVal[0] = `${splitVal[0]}`;
        };
        if(splitVal[1] === "0"){
            splitVal[1] = "="
        }else{
            splitVal[1] = `+${splitVal[1]}`;
        };
        return splitVal;
    };
    if(str.includes('=')){
        let splitVal = str.split("=");
        if(splitVal[0] === "0"){
            splitVal[0] = ""
        }else{
            splitVal[0] = `${splitVal[0]}=`;
        };
        if(splitVal[1] === "0"){
            splitVal[1] = ""
        }else{
            splitVal[1] = `${splitVal[1]}`;
        };
        return splitVal;
    };
    if(str.includes('-')){
        let splitVal = str.split("-");
        if(splitVal[0] === "0"){
            splitVal[0] = "="
        }else{
            splitVal[0] = `${splitVal[0]}`;
        };
        if(splitVal[1] === "0"){
            splitVal[1] = "="
        }else{
            splitVal[1] = `-${splitVal[1]}`;
        };
        return splitVal;
    };

    switch(str){
        case "0":
            return "D";
        case "1":
            return "1=";
        case "2":
            return "2=";
        case "3":
            return "3=";
        case "4":
            return "4=";
        case "5":
            return "5=";
        default:
            return str;
    };
};

export const exportExcelFormat = (exalData, fileName) => {

   const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
   const fileExtension = ".xlsx"

   const ws = XLSX.utils.json_to_sheet(exalData);
   const wb = {Sheets: {'data': ws}, SheetNames: ['data']};
   const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
   const data = new Blob([excelBuffer], {type: fileType});
   FileSaver.saveAs(data, fileName + fileExtension)
};

export const totalAmount = (arr) => {
    const initialValue = 0;
    const sumWithInitial = arr.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
        initialValue,
    );
    return sumWithInitial
}