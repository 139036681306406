import { configureStore } from '@reduxjs/toolkit'
import unitControlSlite from './slices/unitControlSlite'
import userInfoSlice from './slices/userInfoSlice'
import userIdSlice from './slices/userIdSlice'
export const store = configureStore({
    reducer: {
        unitController: unitControlSlite,
        userInfo: userInfoSlice,
        userId: userIdSlice,
    },
  })
