import React from 'react'
import { TableLoaderType } from '../types'

const TableLoading = ({col}: TableLoaderType) => {
  return (
    <tr style={{verticalAlign: 'middle'}}>
        <td
            colSpan={col}
            className="text-center"
        >
            <div
                className="spinner-border spinner-border-sm"
                role="status"
            >
                <span className="visually-hidden">
                    Loading...
                </span>
            </div>
        </td>
    </tr>
  )
}

export default TableLoading
